import api from "@/store/api";
import * as IBAN from "ibantools";
import store from "./../store";

export function maxDate(value = "") {
  if (value == "") return true;
  return new Date(value) <= new Date();
}

export function minDate(value = "") {
  if (value == "") return true;
  return new Date(value) > new Date();
}

export function regNo(value = "") {
  return IBAN.isValidIBAN(value);
}

export function validateIban(value = "") {
  return IBAN.isValidIBAN(value);
}

// export function validateBirthNumber(value = "") {
//   return true;
//   if (value == "") return true;

//   return {
//     $valid: false,
//     data: { message: 'The value must be "something"', extraParams: {} },
//   };
//   // store.dispatch('onboarding/validateBirthNumber', {birthNumber: value}).then((result:any) => {
//   //     return true
//   // }).catch((error: any) => {
//   //     return false;
//   // });
// }

export async function validateBirthNumber(value = "") {
  const response: any = await store.dispatch("onboarding/validateBirthNumber", {
    birthNumber: value,
  });

  if (response["status_code"]) {
    return false;
  }
  return true;
}
